
import Vue from 'vue'

import ImgIllegalAccess from '@/components/images/ImgIllegalAccess.vue'

export default Vue.extend({
  name: 'NotAuthorized',
  components: { ImgIllegalAccess },
  data() {
    return {
      contactSupportLink: `mailto:${Vue.$appSettings.supportEmail}`,
    }
  },
})
