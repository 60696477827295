<template functional>
  <svg
    width="82"
    height="82"
    viewBox="0 0 82 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="vertical-align: top"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41 5.12671C21.468 5.12671 5.58331 21.1595 5.58331 41.0002C5.58331 60.8409 21.468 76.8737 41 76.8737C60.5319 76.8737 76.4166 60.8409 76.4166 41.0002C76.4166 21.1595 60.5319 5.12671 41 5.12671ZM0.583313 41.0002C0.583313 18.4547 18.6503 0.126709 41 0.126709C63.3496 0.126709 81.4166 18.4547 81.4166 41.0002C81.4166 63.5457 63.3496 81.8737 41 81.8737C18.6503 81.8737 0.583313 63.5457 0.583313 41.0002Z"
      fill="#CCC5B0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4795 10.6033L71.0771 67.8828L67.5205 71.3971L10.9229 14.1176L14.4795 10.6033Z"
      fill="#CCC5B0"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImgIllegalAccess',
}
</script>
